<template>
    <div>
        <div class="text-center text-xxl text-bold padding-top" style="color: #00a196;">
            {{name}}
        </div>
        <div class="tempbox" style="padding-bottom: 30px; margin-top: 40px;">
            <div class="flex align-center">
                <strong class="text-xxl">热门招聘大厅</strong>
                <div class="searchbox flex align-center bg-white justify-between">
                    <input type="text" placeholder="关键词搜索" class="topsearchinput padding-left-xs" v-model="keyword">
                    <span class="flex cursor-pointer text-white align-center justify-center" @click="topSearch"><i class="el-icon-search margin-right-xs"></i>搜索</span>
                </div>
            </div>
            <div class="jobbox margin-top-sm">
                <div class="zpdtitem text-white flex align-center justify-center text-xl text-bold radius padding-sm cursor-pointer" v-for="(item,index) in hotJobList" @click="gotoNewJob(item)">
                    {{ item }}
                </div>
            </div>
            <div class="flex align-center margin-top">
                <strong class="text-xxl">高级技能岗位</strong>
            </div>

            <div class="jobbox margin-top-sm">
                <div class="zpdtitem text-white flex align-center justify-center text-xl text-bold radius padding-sm cursor-pointer" v-for="(item,index) in hotJobList2" @click="gotoNewJob(item)">
                    {{ item }}
                </div>
            </div>
        </div>


        <div class="mycombox companylist padding-bottom margin-top-sm" v-loading="loading">
            <div class="companyitembox bg-white radius cursor-pointer" v-for="(item,index) in member" @click="gotoCompany(item)">
                <div class="companyitemtop flex align-center padding-xs">
                    <div style="flex: 0 0 44px;">
                        <el-avatar shape="square" :size="44"
                                   :src="item.logo?item.logo:'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar>
                    </div>

                    <div class="margin-left-xs text-white">
                        <div class="text-cut text-lg">{{ item.companyName }}</div>
                        <div class="text-xs margin-top-xs">{{ item.company }} | {{ item.companyNatureName }}</div>
                    </div>
                </div>
                <div class="padding-sm">
                    <div class="margin-bottom-sm" v-for="(sonitem,sonindex) in item.workList" v-if="sonindex<=1">
                        <div class="flex align-center justify-between text-lg" >
                            <strong class="text-cut text-black" style="width: 160px;">{{ sonitem.workName }}</strong>
                            <strong class="text-black text-xs">{{ sonitem.workEducation }}</strong>
                        </div>
                        <div class="flex align-center justify-between text-xs margin-top-xs" style="color: #3e3e3e;">
                            <span>{{ sonitem.workYears }}</span>
                            <span style="width: 145px;" class="text-cut">{{ sonitem.address }}</span>
                        </div>
                    </div>

                    <div class="flex align-center justify-center">
                        <span class="jobtotal padding-tb-xs padding-lr-sm radius" style="color: #00a196; border: #00a196 solid 1px;">共{{ item.workList.length }}个职位</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _api from "@/api/index";
export default {
    data(){
        return{
            keyword:null,
            name:null,
            id:null,
            loading:false,
            member:[],
            hotJobList:["服务员","设计","司机","收银员","保安","厨师","门卫","保洁员","传菜员","采购员","普工","快递员"],
            hotJobList2:["会计","Java","后端开发","全栈工程师","测试工程师","产品经理","前端开发","UI设计师","交互设计师","淘宝运营","媒介策划","行政总监"],
        }
    },
    created() {
        let index=this.$route.query.index
        this.id=index*1+50
        this.name=this.$route.query.name

        this.getList()


    },
    methods:{
        topSearch(){
            if(!this.keyword){
                this.$message.warning("请输入关键词！")
                return
            }

            this.$router.push({path:"/user/newJobList",query:{key:this.keyword}})

        },
        getList(){
            this.loading=true
            _api.get("/app/user/township/"+this.id).then(res=>{
                this.loading=false
                if(res.code===200){
                    this.member=res.data
                }
            })
        },
        gotoNewJob(key){//去新的职位搜索页
            this.$router.push({path:"/user/newJobList",query:{key:key}})
        },
        gotoCompany(item){
            this.$router.push({path:"/user/companyJobs",query:{companyId:item.id}})
        },
    }
}
</script>
<style scoped>
.mycombox{ width: 1200px; margin: 30px auto 0 auto;}
.companylist{display: grid;grid-template-columns:  repeat(4,283px);grid-template-rows:  repeat(1,257px);column-gap:16px ;row-gap: 16px;}
.companyitemtop{ height: 74px; background: #00a196;}
.companylist div{ overflow: hidden;}

.topbox{ height: 56px; width: 1180px; margin: 0 auto;}
.menu span{ display: block;padding: 0 16px; cursor: pointer;}
.menu span.on{color: #1678ff;}
.tempbox{ width: 1200px; margin: 0 auto 0 auto;}
.jobsearch{ border-radius: 5px;}
.jobsearch span{ padding: 15px 17px; cursor: pointer;}
.jobsearch span:hover{color: #1678ff;}
.jobsearch span.on{ position: relative;color: #1678ff;}
.jobsearch span.on:after{content: ''; position: absolute; width: 80%; height: 3px; background: #1678ff; bottom: 0; left: 10%;}
.jobbox{ display: grid;grid-template-columns:  repeat(4,283px);grid-template-rows:  repeat(2,136px);column-gap:16px ;row-gap: 16px;}
span.btnspan{ display: flex; width: 316px; height: 44px; background: #1678ff;}
.companylist{display: grid;grid-template-columns:  repeat(4,283px);grid-template-rows:  repeat(1,257px);column-gap:16px ;row-gap: 16px;}
.companyitemtop{ height: 74px; background: #00a196;}
.companylist div{ overflow: hidden;}
span.jobtotal { display: block; border: #2a82e4 solid 1px; color: #2a82e4;}
.stateowned{display: grid;grid-template-columns:  repeat(4,283px);grid-template-rows:  repeat(2,120px);column-gap:16px ;row-gap: 16px;}
.banner{margin-bottom: 30px;}
.banner img{ display: block;}

.searchbox{ width: 680px; margin-left: 50px; height: 40px; border: #1678ff solid 2px; border-radius: 8px; overflow: hidden;}
.topsearchinput{ outline: none; border: none; width: 500px; height: 36px;}
.searchbox span{ display: flex; height: 36px; width: 100px; background: #1678ff;}
.zpdtitem{ width: 283px; height: 136px; background: url("../../../assets/zpdtbg.png") no-repeat top left;}
.companyitembox{background: url("../../../assets/companybg.png") no-repeat top left;}
.sydwitem{background: url("../../../assets/sydwbg.png") no-repeat top left;}


</style>

